<template>
    <v-app>
        <div id="printSection"></div>
        <OMenuComp
            :listMenu="listMenu"
        />
        <OMenuMobileComp
            :listMenu="listMenu"
        />
        <div class="app__container pb-16">
            <router-view/>
        </div>
        <OCopyrightComp/>
    </v-app>
</template>
<script>
import {
    OMenuComp,
    OCopyrightComp,
    OMenuMobileComp
} from '../../components/organisms'
export default {
    components: {
        OMenuComp,
        OCopyrightComp,
        OMenuMobileComp
    },
    data: () => ({
        listMenu: []
    }),
    mounted() {
        var dataUser = JSON.parse(localStorage.getItem('agapeconsultores'))
        if (dataUser.user.categorie == 0) {
            this.listMenu = [
                {
                    title: 'Clientes',
                    icon: 'mdi-account',
                    submenu: [
                        {
                            title: "Registro",
                            route: "/clients"
                        },{
                            title: "Historial de Pagos",
                            route: "/payments-history"
                        },{
                            title: "Historial Trabajos",
                            route: "/client-work"
                        }
                    ]
                },
                {
                    title: 'Información',
                    icon: 'mdi-file-table-outline',
                    submenu: [
                        {
                            title: "Tipo de Trabajos",
                            icon: 'mdi-account-cash',
                            route: "/feetype"
                        },{
                            title: 'Tipo de Impuestos',
                            icon: 'mdi-cash-fast',
                            route: '/tax'
                        },{
                            title: 'Tipo de Empresas',
                            icon: 'mdi-human-dolly',
                            route: '/typecompany'
                        },{
                            title: 'Tarifas',
                            icon: 'mdi-account-cash',
                            route: '/rates'
                        }
                    ]
                }, {
                    title: 'Trabajos',
                    icon: 'mdi-clipboard-text',
                    submenu: [
                        {
                            title: "Trabajos",
                            route: "/works"
                        },{
                            title: "Trabajos no ejecutados",
                            route: "/unfinished-work"
                        },{
                            title: "Editar Trabajo",
                            route: "/edit-work"
                        },{
                            title: "Reporte",
                            route: "/job-reports"
                        }
                    ]
                }, {
                    title: 'Cobro de Deudas',
                    icon: 'mdi-cash-plus',
                    route: '/payments',
                    submenu: []
                }, {
                    title: "Historial de Recibos",
                    icon: 'mdi-receipt',
                    route: "/cancellation-history",
                    submenu: []
                }, {
                    title: 'Gastos',
                    icon: 'mdi-cash-minus',
                    submenu: [
                        {
                            title: "Tipo de Gastos",
                            route: "/typeexpense"
                        },{
                            title: "Gastos",
                            route: "/expenses"
                        },{
                            title: "Reporte",
                            route: "/expense-report"
                        }
                    ]
                }, {
                    title: 'Informes',
                    icon: 'mdi-scale-unbalanced',
                    submenu: [
                        {
                            title: "Balance",
                            route: "/balance"
                        },{
                            title: "Cierre de Caja",
                            route: "/close-box"
                        }
                    ]
                }, {
                    title: 'Usuarios',
                    icon: 'mdi-account-multiple',
                    route: '/users',
                    submenu: []
                }
            ]
        } else {
            this.listMenu = [
                {
                    title: 'Trabajos',
                    icon: 'mdi-clipboard-text',
                    submenu: [
                        {
                            title: "Trabajos",
                            route: "/"
                        },{
                            title: "Trabajos no ejecutados",
                            route: "/unfinished-work"
                        }
                    ]
                }, {
                    title: 'Cobro de Deudas',
                    icon: 'mdi-cash-plus',
                    route: '/payments',
                    submenu: []
                }, {
                    title: "Historial de Recibos",
                    icon: 'mdi-receipt',
                    route: "/cancellation-history",
                    submenu: []
                }, {
                    title: 'Gastos',
                    icon: 'mdi-cash-minus',
                    route: '/expenses',
                    submenu: []
                }, {
                    title: 'Cierre de Caja',
                    icon: 'mdi-scale-unbalanced',
                    route: '/close-box',
                    submenu: []
                }
            ]
        }
    }
}
</script>